import React from 'react';

const LoadingBanner = () => {
  return (
    <div className="loading-banner">
      <span className="loading-banner-text">
        Creating Images...This may take several minutes.
        <br />
        Current limit is 10 active cards at a time.
      </span>
    </div>
  );
};

export default LoadingBanner;