// src/pages/DemoPage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthProvider, useAuth } from "../utils/auth/authContext";
import axiosInstance from "../utils/axiosConfig";
import CryptoJS from "crypto-js";
import Header from "../components/MainLayout/Header";
import CardForm from "../components/MainLayout/CardForm.jsx";
import LeftSidebar from "../components/MainLayout/LeftSidebar.jsx";
import RightSidebar from "../components/MainLayout/RightSidebar.jsx";
import ErrorComponent from "../components/Shared/ErrorMessage";
import LoadingBanner from "../components/MainLayout/LoadingBanner.jsx";

const DemoPage = () => {
  const [sidebarText, setSidebarText] = useState("");
  const [sidebarWeight, setSidebarWeight] = useState(5);
  const [counter, setCounter] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [isIpEncrypted, setIsIpEncrypted] = useState(false);

  const [otherValues, setOtherValues] = useState({
    cardName: 5,
    color: 0,
    typeLine: 0,
    keywords: 0,
    tokens: 0,
    favorText: 0,
  });

  const [engineValues, setEngineValues] = useState({
    cfg_scale: 7,
    clip_guidance_preset: "NONE",
    sampler: "DDIM",
    steps: 25,
    stylePreset: "fantasy-art",
  });

    useEffect(() => {
        fetchUserIpAndCheckAgreement();
    }, []);

    const fetchUserIpAndCheckAgreement = async () => {
    try {
        // Fetch the user's IP address
        const response = await axios.get("https://api.ipify.org?format=json");
        const ip = response.data.ip.trim(); // Ensure no extra spaces
        const encryptedIp = CryptoJS.SHA256(ip).toString();
        console.log("Encrypted IP (Frontend):", encryptedIp);

        localStorage.setItem("encryptedIp", encryptedIp);

        // Check if the encrypted IP already exists in the database
        const checkResponse = await axiosInstance.get(`/api/auth/usage-count/${encryptedIp}`, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });

        if (checkResponse.status === 200) {
        const { found, usageCount } = checkResponse.data;
            console.log("checkResponse: ", checkResponse);
        // If user is found, set counter and agreement status
        if (found) {
            console.log("usageCount: ", usageCount);
            setCounter(usageCount);
            setAgreementAccepted(true); // User has already accepted the agreement
        } else {
            // If user is not found, show the agreement card
            setAgreementAccepted(false);
        }
        }
    } catch (error) {
        console.error("Error fetching IP address or checking agreement status:", error);
        setErrorMessage("An error occurred while checking your agreement status. Please try again.");
    }
    };

  const fetchUsageCount = async () => {
    try {
      const encryptedIp = localStorage.getItem("encryptedIp");
      if (!encryptedIp) {
        setErrorMessage("Unable to retrieve encrypted IP. Please try again.");
        return;
      }

      const response = await axiosInstance.get(`/api/auth/usage-count/${encryptedIp}`);
      if (response.status === 200) {
        setCounter(response.data.usageCount);
      } else {
        console.error("Error:", response.data.error);
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      console.error("Error fetching usage count:", error);
      setErrorMessage("An error occurred while fetching your usage count.");
    }
  };

  // Frontend: Handle Agreement Accept
  const handleAgreementAccept = async () => {
    try {
      // Fetch and encrypt the user's IP
      const response = await axios.get("https://api.ipify.org?format=json");
      const ip = response.data.ip.trim();
      const encryptedIp = CryptoJS.SHA256(ip).toString();

      // Send the encrypted IP to the backend to register agreement acceptance
      const postResponse = await axiosInstance.post("/api/auth/accept-agreement", {
        encryptedIp,
      });

      if (postResponse.status === 201) {
        console.log("Agreement accepted successfully.");
        setAgreementAccepted(true); // Now the user has accepted, show the rest of the app
        fetchUsageCount(); // Optionally fetch usage count after agreement is accepted
      } else {
        console.error("Error:", postResponse.data.error);
        setErrorMessage(postResponse.data.error || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Error accepting agreement:", error);
      setErrorMessage("An error occurred while processing your acceptance. Please try again.");
    }
  };

  const handleClearError = () => {
    setErrorMessage("");
  };

    const updateCounterInBackend = async (newCounterValue) => {
    try {
        // Fetch and encrypt the user's IP address
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ip = ipResponse.data.ip.trim();
        const encryptedIp = CryptoJS.SHA256(ip).toString();
        console.log("Encrypted IP (Frontend):", encryptedIp);

        const accessToken = localStorage.getItem("accessToken");

        // Send the request to update demo tokens using encryptedIp
        const response = await axiosInstance.put(
        `/api/user/update-demotokens`, // No need for userId, using encryptedIp
        {
            tokens: newCounterValue,
        },
        {
            headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-Encrypted-IP": encryptedIp, // Include the encrypted IP in the headers
            },
        }
        );

        return response.status === 200 && response.data && response.data.tokens === newCounterValue;
    } catch (error) {
        throw error;
    }
    };

  const decrementCounter = (decrementAmount = 1) => {
    setCounter((prevCounter) => {
      const newCounterValue = Math.max(0, prevCounter - decrementAmount);

      if (newCounterValue !== prevCounter) {
        updateCounterInBackend( newCounterValue)
          .then((success) => {
            if (!success) {
              console.error("Backend update failed");
            }
          })
          .catch((error) => {
            console.error("Error updating counter in backend:", error);
            setErrorMessage("Failed to update counter in the backend.");
          });
      }

      return newCounterValue;
    });
  };

  return (
    <div className="container">
      {!agreementAccepted ? (
        <div className="agreement-container">
          <div className="agreement-card">
            <h2>Agreement</h2>
            <h3>Data Collection and Usage Agreement </h3>
            <p style={{ marginBottom: "8px", height: "50px" }}>
              By using this demo, you acknowledge and consent to the collection and encryption of your IP address.
              <br />
            </p>
            <p style={{ marginBottom: "50px", height: "100px" }}>
              The collection of your IP address is solely for the purpose of managing and limiting your access to the demo. We do not use your IP address for any other purpose, and it will not be shared with any third parties. Your IP address will be encrypted to protect your privacy and ensure the security of your information. We are committed to
              safeguarding your data in accordance with industry standards and applicable privacy laws.
            </p>
            <button className="login-btn" onClick={handleAgreementAccept}>
              I Agree
            </button>
          </div>
        </div>
      ) : (
        <>
          {isLoading && <LoadingBanner />}
          <Header isConnected={false} isDemo={true} />
          <ErrorComponent errorMessage={errorMessage} onClearError={handleClearError} />
          <div className="content">
            <LeftSidebar text={sidebarText} weight={sidebarWeight} setText={setSidebarText} setWeight={setSidebarWeight} otherValues={otherValues} setOtherValues={setOtherValues} engineValues={engineValues} setEngineValues={setEngineValues} counter={counter} errorMessage={errorMessage} />
            <CardForm sidebarText={sidebarText} sidebarWeight={sidebarWeight} otherValues={otherValues} engineValues={engineValues} decrementCounter={decrementCounter} counter={counter} isLoading={isLoading} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} />
          </div>
        </>
      )}
    </div>
  );
};

export default DemoPage;
