import React from "react";
import axiosInstance from '../../utils/axiosConfig.js';
import patreonLogo from '../../assets/Patreon Brand Assets/Patreon Wordmark/JPG/Digital-Patreon-Wordmark_FieryCoralOnWhite-Sm.jpg'; // Make sure to provide the correct path to your image

const Header = ({ isConnected, isDemo }) => {

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('jwt'); // if you have stored the Patreon token separately
    // Redirect to login or any other page you wish after logout
    window.location.href = '/login'; 
  };
  const connectWithPatreon = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axiosInstance.get(`/patreon/auth/patreon`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          token: token
        }
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.redirectURL) {
          window.location.href = data.redirectURL;
        }
      }
    } catch (error) {
      console.error('Error connecting with Patreon:', error);
    }
  };

  return (
    <div className="header">
      {!isDemo && (
        <div className="left-header-content">
          {isConnected ? (
            <>
              <p>
                Donate on Patreon <br />
                to create new images.
              </p>
            <a
              href="https://www.patreon.com/ArcaneProxies"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={patreonLogo}
                alt="Donate on Patreon"
                className="patreon-img"
              />
            </a>
            </>
          ) : (
            <>
              <p>
                Connect and donate on Patreon <br />
                to create new images.
              </p>
              <button
                onClick={connectWithPatreon}
                className ="patreon-button"
              >
              <img
                src={patreonLogo}
                alt="Donate on Patreon"
                className="patreon-img"
              />
              </button>
            </>
          )}
        </div>
      )}
      <span className="app-name">Arcane-Proxies</span>
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </div>
  );
};

export default Header;